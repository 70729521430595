import "./App.css";
import Routers from "./Routers/Router";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  AOS.init();

  return (
    <div className="App">
      <Routers />
    </div>
  );
}

export default App;
