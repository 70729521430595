import React from "react";

const PComponent = (props) => {
  return (

    <div className="package-main-content">
      {props.p_Component.map((value, index) => (

        <div key={index} className="p-container">
          <img src={value.img} alt="Package Background Image" />
          <div className="p-content">

            <div className="p-details">
              <p className="p-title">{value.title}</p>
              {/* <p className="p-description">{value.description}</p> */}
              <div className="p-sports">
                <span>{value.sports}</span>
              </div>
            </div>
            <div className="p-actions">
              <a  href="tel: 080 22222224" target="_blank" className="p-price">INR {value.price}</a>
              <a href="tel: 080 22222224" target="_blank" className="p-book">Contact us</a>
            </div>

          </div>
        </div>
      ))}

    </div>

     

  );
};
export default PComponent;
