import React, { useState, useEffect } from "react";
import "./FloatingCTA.scss";
import Call from "../Assets/Call.svg";
import WhatsApp from "../Assets/WhatsApp.svg";



const FloatingCTA = () => {

  return (
    <div className="main-fab-conatiner">
      <a  href="tel: 080 22222224" target="_blank" className="call-icon" >
        <img src={Call} alt="Call" />
      </a>
      <a href="https://wa.me/919606022393" target="_blank" className="whatsapp-icon">
        <img src={WhatsApp} alt="What's App" />
      </a>
    </div>
  );
};
export default FloatingCTA;
