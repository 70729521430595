import React from 'react';
import "./Packages.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FAB from "../FloatingCTA/FloatingCTA";
import Package_Cards from "./PackageCard";
import CorporatePackage from "./CorporatePackage";

import { corporatePackage, pData } from "../Scripts/Data";
import PackageBg from "../Assets/Packages/package-hero.png"



const Packages = () => {
  return (
    <div className="package-main-container">
      <Header />
      <FAB/>
      <div className="p-main-title">
      <img src={PackageBg} alt="adventure background" />

        <h1>Packages</h1>
      </div>
      <section>
        <Package_Cards p_Component={pData} />
        <CorporatePackage p_Component={corporatePackage} />

      </section>

      <Footer />
    </div>
  );
};
export default Packages;
