import React from "react";
import "./MenuModal.scss";

const FullScreenModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="full-screen-modal-overlay">
      <div className="full-screen-modal">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default FullScreenModal;



