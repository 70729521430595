import React from "react";
import "./Rooms.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FAB from "../FloatingCTA/FloatingCTA";
//Rooms
import RoomBg from "../Assets/Rooms/rooms-bg.png";
import Room1 from "../Assets/Rooms/room-1.png";
import Room2 from "../Assets/Rooms/Suite-room.png";
import Tent from "../Assets/Rooms/tent-stay.png";


const Rooms = () => {
  return (
    <div className="main-container">
      <Header />
      <FAB/>
      <div className="rooms-bg-hero">
        <img src={RoomBg} alt="Rooms Hero Header Background" />
        <h1>
          Luxurious accomodations designed for ultimate comfort and relaxation
        </h1>
      </div>

      {/* Rooms Description */}

      <div className="rooms-des">
        <h1>Elevate Your Experience where Elegance Meets Adventure</h1>
        <p>
          Sportico features 9 lavish suites and 35 superior rooms where luxury
          meets every guest's satisfaction. Our rooms are designed with elegant
          decor and premium facilities to fulfill your every need. We ensure you
          a stay that you will remember forever in your memories.
        </p>
      </div>

      {/* Room Types */}

      <div className="room-category-container">
        <div className="room-type" data-aos="fade-up" data-aos-duration="1000">
          <img src={Room1} alt="Type of room in the resort" />
        </div>
        <div className="room-details-main"  >
          <div className="room-details" >
            <h1>Superior Room</h1>
            <span>starts from INR 4999 / person / night</span>
            <p>
              Escape into superior comfort, where every detail is crafted for
              your pleasure
            </p>
            <a href="https://bookings.resavenue.com/resBooking/availsearch?regCode=VVGZ0118" target="_blank" className="book-room-stay">
            Book now
          </a>
          </div>
        </div>
      </div>
      <div className="room-category-container">
        <div className="room-type" data-aos="fade-up" data-aos-duration="1000">
          <img src={Room2} alt="Type of room in the resort" />
        </div>
        <div className="room-details-main" >
          <div className="room-details">
            <h1>Suite Room</h1>
            <span>starts from INR 6499 / person / night</span>
            <p>
              Elevate your stay with a lavish suite experience, capture
              beautiful views of the resort in spacious balcony.
            </p>
            <a href="https://bookings.resavenue.com/resBooking/availsearch?regCode=VVGZ0118" target="_blank" className="book-room-stay">
            Book now
          </a>
          </div>
        </div>
      </div>

      <div className="room-category-container">
        <div className="room-type" data-aos="fade-up" data-aos-duration="1000">
          <img src={Tent} alt="Type of room in the resort" />
        </div>
        <div className="room-details-main">
          <div className="room-details">
            <h1>Tent Stay</h1>
            {/* <span>from INR 7999 / person / night</span> */}
            <p>
            Experience the thrill of adventure in our cozy tent stay amidst the tranquil surroundings at Sportico
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Rooms;
