import React from "react";

const CorporatePackage = (props) => {
  return (

       <div className="c-package-main-content">
      {props.p_Component.map((value, index) => (

        <div key={index} className="cp-container">
          <img src={value.img} alt="Package Background Image" />
          <div className="cp-content">

            <div className="cp-details">
              <p className="cp-title">{value.title}</p>
              <p className="cp-description">{value.description}</p>
              <div className="cp-sports">
                <p>{value.sports}</p>
              </div>
            </div>
            <div className="cp-actions">
            <a href="tel: 080 22222224" target="_blank" className="cp-book">Contact us</a>
              <a href="tel: 080 22222224" target="_blank" className="cp-price">INR {value.price}</a>
            </div>

          </div>
        </div>
      ))}

    </div>

  );
};
export default CorporatePackage;
