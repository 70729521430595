import React from "react";
import "./Spa.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FAB from "../FloatingCTA/FloatingCTA";
//Assets
import HeroBg from "../Assets/Amenities/amenities-hero.png";
import SpaHero from "../Assets/Amenities/spa-hero.png";
import Therapists from "../Assets/Amenities/Therapists.svg";
import Hygiene from "../Assets/Amenities/hygiene.svg";
import R_Therapies from "../Assets/Amenities/researched-therapies.svg";
import Quality_Products from "../Assets/Amenities/quality-products.svg";
import Feature1 from "../Assets/Amenities/feature01.png";
import Feature2 from "../Assets/Amenities/feature02.png";
import Feature3 from "../Assets/Amenities/feature03.jpg";
import Feature4 from "../Assets/Amenities/feature04.png";


const Amenities = () => {
  return (
    <div className="amenities-main">
      <Header />
      <FAB/>
      <div className="amenities-hero-bg">
        <img src={HeroBg} alt="amenities background" />
        <h1>Rejuvenate Your Body, Mind and Soul</h1>
      </div>
      <div className="spa-section">
        <div className="spa-header">
          <p className="amenity-title">spa</p>
          <h1 className="amenity-heading">Cynara</h1>
          <p className="amenity-desc">
            Melt away stress and indulge in pure bliss at our world-class spa.
            From rejuvenating massages and facials to aromatherapy and
            hydrotherapy treatments, our expert therapists will tailor a journey
            to your unique needs.
          </p>
          <div className="spa-hero-img" data-aos="fade-up" data-aos-duration="1000">
            <img src={SpaHero} alt="amenities background" />
          </div>
          <div className="spa-offerings">
            <div className="promise" data-aos="fade-up" data-aos-duration="1000" >
              <img src={Therapists} alt="spa offerings" className="type" />
              <p>Certified Therapists</p>
            </div>

            <div className="promise" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <img src={Hygiene} alt="spa offerings" className="type" />

              <p>Commitment to Hygiene</p>
            </div>

            <div className="promise" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
              <img src={Quality_Products} alt="spa offerings" className="type" />

              <p>Quality Products</p>
            </div>

            <div className="promise" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
              <img src={R_Therapies} alt="spa offerings" className="type" />

              <p>Researched Therapies</p>
            </div>
          </div>
          <div className="spa-features">
            <div className="feature" data-aos="fade-up" data-aos-duration="1000" >
              <div className="feature-img-1" >
                <img src={Feature1} alt="amenities background" />
              </div>
              <p>Body Massages and facials</p>
            </div>

            <div className="feature" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <div className="feature-img-2">
                <img src={Feature2} alt="amenities background" />
              </div>
              <p>Salt & steam room</p>
            </div>

            <div className="feature" data-aos="fade-up" data-aos-duration="1000" >
              <div className="feature-img-2">
                <img src={Feature3} alt="amenities background" />
              </div>
              <p>Body Scrubs & Clay Wraps</p>
            </div>

            <div className="feature" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <div className="feature-img-1">
                <img src={Feature4} alt="amenities background" />
              </div>
              <p>Manicure & Pedicure</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Amenities;
