import React from "react";
import "./Amenities.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FAB from "../FloatingCTA/FloatingCTA";
//Assets

import HeroBg from "../Assets/Amenities/pool-img.png";
import Divider from "../Assets/Amenities/Divider.svg";
import PubHero from "../Assets/Amenities/pub-hero.png";
import BarHero from "../Assets/Amenities/bar-hero.jpg";
import Resto1 from "../Assets/Amenities/resto-img-1.png";
import Resto2 from "../Assets/Amenities/resto-img-2.png";
import Resto3 from "../Assets/Amenities/resto-img-3.png";
import Amenity1 from "../Assets/Amenities/other-amenity-1.png";
import Amenity2 from "../Assets/Amenities/other-amenity-2.png";
import Amenity3 from "../Assets/Amenities/other-amenity-3.png";



const Amenities = () => {
  return (
    <div className="amenities-main">
      <Header />
      <FAB/>
      <div className="amenities-hero-bg">
        <img src={HeroBg} alt="amenities background" />
        <h1>Experience the Extraordinary</h1>
      </div>
      
      <div className="pub-section">
        <img
          className="pub-divider"
          src={Divider}
          alt="Diver background"
        />

        <div className="pub-header">
          <div className="amenity-heading-main">
            <p className="amenity-title">Pub</p>
            <h1 className="amenity-heading">Frequency</h1>
          </div>
          <div>
            <p className="amenity-desc">
              Explore Bangalore's best cocktail pub, The Frequency at Sportico
              is a trendy hotspot brings a global bar culture to Bangalore, with
              its vibrant decor and diverse menu.
            </p>
          </div>
        </div>
        <div className="pub-hero-img" data-aos="fade-up" data-aos-duration="1000" >
          <img src={PubHero} alt="amenities background" />
        </div>
        <p className="amenity-desc">
          Ready to Dance on our biggest dance floor, with DJ beats that blend
          Indian and Hip Hop, creating a night to remember . Sportico Frequency
          Pub is perfect place for party freaks and one of the most famous night
          clubs in Bangalore.
        </p>
      </div>

      <div className="bar-section">
        <div className="bar-hero-img" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
          <img src={BarHero} alt="amenities background" />
        </div>

        <div className="bar-header">
          <p className="amenity-title">RESTO-BAR</p>
          <h1 className="amenity-heading">Mist roof top</h1>
          <p className="amenity-desc">
            Elevate your evenings at Mist Rooftop Bar, one of the largest and
            premium rooftop bars near Bangalore. Immerse yourself in a
            sophisticated ambiance, complete with premium drinks and handcrafted
            cocktails. Whether it's a romantic date or a night out with friends,
            Mist Rooftop Bar offers the perfect destination for a relaxing
            evening.
          </p>
        </div>
        <img
          className="bar-divider"
          src={Divider}
          alt="Diver background"
        />
      </div>

      <div className="resto-section">
        <div className="resto-header">
          <p className="amenity-title">Restaurant</p>
          <h1 className="amenity-heading">Saffron</h1>
          <p className="amenity-desc">
            Savor the diverse culinary delights at Sportico luxury resort near
            Bangalore. Experience Indian, Chinese, and Continental cuisines with
            vegetarian and non-vegetarian buffet options in our Saffron
            restaurant. Our expert chefs craft each dish into a masterpiece,
            providing the ultimate dining experience.
          </p>
        </div>
        <div className="resto-img-main">
          <div className="">
            <img src={Resto1} alt="amenities background" data-aos="fade-up" data-aos-duration="1000" />
            <img src={Resto2} alt="amenities background" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100"/>
          </div>
          <div className="">
            <img src={Resto3} alt="amenities background" data-aos="fade-up" data-aos-duration="1000" />
          </div>
        </div>
      </div>

      <div className="other-amenities-section">
        <h1 className="amenity-heading">
          amenities designed to cater to your every desire
        </h1>

        <div className="other-amenities">
          <div className="amenity" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
            <img src={Amenity1} alt="amenitiy image" />
            <h6>Pool Party</h6>
            <p>
              Splash into moments of ecstasy at the biggest pool party in
              Bangalore! Join us for the high-energy vibes at the best luxury
              resort in Bangalore.
            </p>
          </div>

          <div className="amenity" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
            <img src={Amenity2} alt="amenitiy image" />
            <h6>Dhvani</h6>
            <p>
              Our open-air amphitheatre, hosting diverse events from yoga
              sessions to intimate musical gatherings, plays, and meetings.
              Experience the events under the open sky at Sportico Bangalore.
            </p>
          </div>

          <div className="amenity" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">
            <img src={Amenity3} alt="amenitiy image" />
            <h6>Fitness Center</h6>
            <p>
              Elevate your well-being with Sportico's wellness offerings. Our
              well-equipped fitness center equipped with modern facilities
              ensuring you Stay active and healthy!s
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Amenities;
