import React, { useState, useEffect } from "react";
import "./Header.scss";
import header_logo from "../Assets/sportico-logo.svg";
import { NavLink } from "react-router-dom";
import { AiOutlineShopping } from "react-icons/ai";
import { IoMenu } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Modal from "../MenuModal/MenuModal";
import ModalContent from "../MenuModal/ModalContent";


const Header = () => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Get the scroll position
      const scrollPosition = window.scrollY;

      // Set the sticky state based on the scroll position
      setSticky(scrollPosition > 0);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={isSticky ? "sticky-header" : "header-container"}>
      <div className="main header">
        <a className="main-cta" onClick={handleOpenModal}>
        
          <IoMenu className="cta-icon" />
          Menu
        </a>
        <a className="logo" href="/">
          <img className="" src={header_logo} alt="logo" />
        </a>
        <a href="https://bookings.resavenue.com/resBooking/availsearch?regCode=VVGZ0118" className="main-cta" target="_blank">
          Book now
          <AiOutlineShopping className="cta-icon" />
        </a>
      </div>

      <div className="sub header">
        <ul className="nav-links">
        <li>
            <NavLink to="/" activeclassname="active" className="menu-links">
              Home
            </NavLink>
          </li>
        <li>
            <NavLink
              to="/rooms"
              activeclassname="active"
              className="menu-links"
            >
              Accomodation
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/packages"
              activeclassname="active"
              className="menu-links"
            >
              Packages
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/amenities"
              activeclassname="active"
              className="menu-links"
            >
              Experiences
            </NavLink>
          </li>
        
          
          <li>
            <NavLink
              to="/adventure"
              activeclassname="active"
              className="menu-links"
            >
              Adventures
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/events"
              activeclassname="active"
              className="menu-links"
            >
              Events
            </NavLink>
          </li> 
          <li>
            <NavLink
              to="/spa"
              activeclassname="active"
              className="menu-links"
            >
              SPA
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Modal Menu Starts here */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <section className="">
          <div className="modal-menu-container">
            <IoIosCloseCircleOutline onClick={handleCloseModal} className="x" />
          </div>
          <ModalContent/>
        </section>
      </Modal>
    </div>
  );
};
export default Header;
