import React from "react";
import "./About.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import chairman from "../Assets/Chairman.png";
import director from "../Assets/Director.png";


const About = () => {
  return (
    <div className="about-main">
      <Header />
      {/* Hero section of About us */}
      <div className="about-hero-section">
        <p>
          {" "}
          A massive <span>20 Acres</span> adventure resort.
        </p>
        <h1 className="">A Haven Where Luxury meets Adventure</h1>
        <p>
          Sportico is a popular adventure sports resort located in Bangalore,
          India . Our luxury resort offers cozy accommodations, from modern
          rooms to premium suites. Near Bangalore you can Relax at Mandala Spa,
          enjoy the vibrant nightlife at Frequency Pub, savor signature dishes
          at Saffron Restaurant, and chill at the Misty Rooftop Bar. With
          activities like swimming, quad biking, ziplining, kayaking, Bungee
          jump and paintball, an ideal destination for families, couples,
          and corporate groups.
        </p>
      </div>
      <div className="about-team">
        <div className="team-img">
          <img src={chairman} alt="Profile image of the resort Chairman" />
          <h6 className="">Nisarga Narayanaswamy LN</h6>
          <p>Chairman</p>
        </div>
        <div className="team-img">
          <img src={director} alt="Profile image of the resort Chairman" />
          <h6 className="">Ramananda Sagar</h6>
          <p>Managing Director</p>
        </div>
        {/* <div className="team-description">
          <p>
            Sportico Bangalore Resort is not just a place to stay; it's a
            destination that caters to all your desires, from tranquil
            relaxation to heart-pounding adventures. Welcome to a realm where
            luxury, entertainment, and excitement meets to create an experience
            which lasts lifetime. Sportico Bangalore Resort is not just a place
            to stay; it's a destination that caters to all your desires,
          </p>
        </div> */}
      </div>

      <Footer />
    </div>
  );
};

export default About;
