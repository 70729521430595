import React from 'react';
import "./Adventure.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FAB from "../FloatingCTA/FloatingCTA";
import { MdArrowRightAlt } from "react-icons/md";
//Adventure Images
import Freefall from "../Assets/Adventure/Freefall.png";
import DirtKart from "../Assets/Adventure/Dirt_Kart.png";
import WallClimbing from "../Assets/Adventure/Wall_Climbing.png";
import PaintBall from "../Assets/Adventure/Paint_Ball.png";
import Zipline from "../Assets/Adventure/Zipline.png";
import HighRopeCourse from "../Assets/Adventure/High_Rope_Course.png";
import AdvBg from "../Assets/Adventure/adventure-bg.png";
import Carrom from "../Assets/Adventure/carrom.png";
import Billiards from "../Assets/Adventure/billiards.png";
import Cricket from "../Assets/Adventure/cricket.png";
import Badmintion from "../Assets/Adventure/badmintion.png";
import PoolBall from "../Assets/Adventure/pool-ball.png";
import Kayaking from "../Assets/Adventure/kayaking-img.png";
import BubbleSoccer from "../Assets/Adventure/Bubble-soccer.jpg";
import MeltDown from "../Assets/Adventure/meltdown.jpg";
import ArcheryTag from "../Assets/Adventure/ArcheryTag.jpg";


const Adventure = () => {
  return (
    <div className="adv-main-container">
      <Header />
      <FAB/>
      <div className="adv-hero-img">
        <img src={AdvBg} alt="adventure background" />
        <div className="adv-hero-content">
          <h1>Your next adventure starts here</h1>
          <a href="/packages" target="_blank" className="package-cta">
            View packages
          </a>
        </div>
      </div>
      <div className="adv-section-two">
        <p>
          Get ready for an adrenaline rush at Sportico, Bangalore's top spot for
          outdoor adventures! Located in Malur, near Whitefield, our adventure
          sports offers everything from kayaking to zip-lining, dirt karting to
          team challenges. Join us for an unforgettable journey of excitement
          and exploration in Sportico Luxury resort Bangalore!
        </p>
      </div>
      <div className="adv-games-main">
        <div className='adv-title'>
          <h1>Adventure Sports</h1>
          {/* <span>Opening soon</span> */}
        </div>

        <div className="adv-games-row1"  >
          <div className="adv-games-content">
            <div className="adv-game sport1" data-aos="fade-up" data-aos-duration="1000">
              <p> Dirt Kart</p>
              <img src={DirtKart} alt="Dirt_kart" />
            </div>
          </div>
          <div className="adv-games-content ">
            <div className="adv-game" data-aos="fade-up" data-aos-duration="1500">
              <p>Wall Climbing</p>
              <img src={WallClimbing} alt="Wall_Climbing" />
            </div>
            <div className="adv-game" data-aos="fade-up" data-aos-duration="1000">
              <p>Freefall</p>
              <img src={Freefall} alt="Freefall" />
            </div>
          </div>
          <div className="adv-games-content">
            <div className="adv-game sport1" data-aos="fade-up" data-aos-duration="1500">
              <p>Paint Ball</p>
              <img src={PaintBall} alt="Paint_Ball" />
            </div>
            <div className="adv-last-row">
              <div className="adv-game" data-aos="fade-up" data-aos-duration="1000">
                <p>Zipline</p>
                <img src={Zipline} alt="Zipline" />
              </div>
              <div className="adv-game" data-aos="fade-up" data-aos-duration="1000">
                <p>High Rope Course</p>
                <img src={HighRopeCourse} alt="High_Rope_Course" />
              </div>
            </div>
          </div>
        </div>
        <div className="adv-games-row1"  >
        <div className="adv-games-content">

         <div className="adv-last-row">
         <div className="adv-game" data-aos="fade-up" data-aos-duration="1000">
                <p>Bubble Soccer</p>
                <img src={BubbleSoccer} alt="High_Rope_Course" />
              </div>
              <div className="adv-game" data-aos="fade-up" data-aos-duration="1000">
                <p>Melt Down</p>
                <img src={MeltDown} alt="High_Rope_Course" />
              </div>
              <div className="adv-game" data-aos="fade-up" data-aos-duration="1000">
                <p>Archery Tag</p>
                <img src={ArcheryTag} alt="High_Rope_Course" />
              </div>
            </div>
      </div>
        </div>
      </div>

      <div className="adv-section-three">
        <div className="games">
          <div
            className="adv-game-img"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={Carrom} alt="event image" />
          </div>
          <div
            className="adv-game-img"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <img src={Billiards} alt="event image" />
          </div>
          <div className="adv-game-content">
            <div className='adv-title'>

              <h1>Indoor Games</h1>
            </div>
            <ul>
              <li>
                {" "}
                <MdArrowRightAlt />
                Billiards
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Air Hockey
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Caroms
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                FoosBall
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Table Tennis
              </li>
            </ul>
          </div>
        </div>
        <div className="games outdoor-main">
          <div
            className="adv-game-img"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={Cricket} alt="event image" />
          </div>
          <div className="adv-game-content">
            <div className='adv-title'>

              <h1>Outdoor Games</h1>
            </div>
            <ul>
              <li>
                {" "}
                <MdArrowRightAlt />
                Cricket
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Football
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Volleyball
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Badminton
              </li>
           
              <li>
                {" "}
                <MdArrowRightAlt />
                Human FoosBall
              </li>
            </ul>
          </div>
          <div
            className="adv-game-img"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <img src={Badmintion} alt="event image" />
          </div>
        </div>

        <div className="games">
          <div className="adv-game-content">
            <div className='adv-title'>
              <h1>Aqua Games</h1>
              {/* <span>Opening soon</span> */}
            </div>
            <ul>
              <li>
                {" "}
                <MdArrowRightAlt />
                Kayaking
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Watercycles
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Pool Throw Ball
              </li>
              <li>
                {" "}
                <MdArrowRightAlt />
                Pool Volley Ball
              </li>
            </ul>
          </div>
          <div className="adv-game-img">
            <img
              src={PoolBall}
              alt="event image"
              data-aos="fade-up"
              data-aos-duration="1000"
            />
          </div>
          <div className="adv-game-img">
            <img
              src={Kayaking}
              alt="event image"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Adventure;
