import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Menu from '../Assets/Cynara-Menu.pdf'
import "./Spa.scss";



pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


export default function SpaMenu() {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

  return (
    <div className='menu-pdf'>
    <Document
    file={Menu}
    onLoadSuccess={onDocumentLoadSuccess}
  >
    {Array.from(
      new Array(numPages),
      (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
        />
      ),
    )}
  </Document>
  </div>
  );
}